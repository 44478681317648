<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ familyMember.title || 'New Family Member' }}</h1>
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab exact-path :to="'/family-members/' + familyMember.id">Details</v-tab>
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="tabs" transition="false">
          <v-card flat>
            <v-card-text>
              <router-view :family_member_id="familyMember.id" />
            </v-card-text>
          </v-card>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'FamilyMemberView',
  beforeRouteEnter(to, from, next) {
    store.dispatch('family/get', to.params.id).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters('family', ['loading', 'error', 'familyMember', 'meta'])
  },
  data: () => ({
    tabs: null,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Family Members',
        exact: true,
        to: '/family-members'
      },
      {
        text: 'Family Member',
        disabled: true,
        exact: true,
        to: '/family-members/:id'
      }
    ]
  })
};
</script>
